.wrapper {
    padding: 50px 30px;
    text-align: center;
}

.paragraph {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
}

.link {
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
}